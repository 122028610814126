import React from "react";
import "./App.css";
import { CardTransactionSimulationForm } from "./components/CardTransactionSimulationForm";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <h2>Card Transaction Simulator</h2>
      <br />
      <CardTransactionSimulationForm></CardTransactionSimulationForm>
      <ToastContainer />
    </div>
  );
}

export default App;
