interface MerchantCategoryCode {
  mcc: string;
  category: string;
}

export const merchantCategoryCodes: MerchantCategoryCode[] = [
  {
    mcc: "8931",
    category: "Accounting/Bookkeeping Services",
  },
  {
    mcc: "7623",
    category: "A/C, Refrigeration Repair",
  },
  {
    mcc: "7311",
    category: "Advertising Services",
  },
  {
    mcc: "0763",
    category: "Agricultural Cooperative",
  },
  {
    mcc: "4511",
    category: "Airlines, Air Carriers",
  },
  {
    mcc: "4582",
    category: "Airports, Flying Fields",
  },
  {
    mcc: "4119",
    category: "Ambulance Services",
  },
  {
    mcc: "7996",
    category: "Amusement Parks/Carnivals",
  },
  {
    mcc: "5937",
    category: "Antique Reproductions",
  },
  {
    mcc: "5932",
    category: "Antique Shops",
  },
  {
    mcc: "7998",
    category: "Aquariums",
  },
  {
    mcc: "8911",
    category: "Architectural/Surveying Services",
  },
  {
    mcc: "5971",
    category: "Art Dealers and Galleries",
  },
  {
    mcc: "5970",
    category: "Artists Supply and Craft Shops",
  },
  {
    mcc: "5531",
    category: "Auto and Home Supply Stores",
  },
  {
    mcc: "7531",
    category: "Auto Body Repair Shops",
  },
  {
    mcc: "6011",
    category: "Automated Cash Disburse",
  },
  {
    mcc: "5542",
    category: "Automated Fuel Dispensers",
  },
  {
    mcc: "8675",
    category: "Automobile Associations",
  },
  {
    mcc: "5533",
    category: "Automotive Parts and Accessories Stores",
  },
  {
    mcc: "5532",
    category: "Automotive Tire Stores",
  },
  {
    mcc: "7535",
    category: "Auto Paint Shops",
  },
  {
    mcc: "7538",
    category: "Auto Service Shops",
  },
  {
    mcc: "9223",
    category:
      "Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)",
  },
  {
    mcc: "5462",
    category: "Bakeries",
  },
  {
    mcc: "7929",
    category: "Bands, Orchestras",
  },
  {
    mcc: "7230",
    category: "Barber and Beauty Shops",
  },
  {
    mcc: "7995",
    category: "Betting/Casino Gambling",
  },
  {
    mcc: "5940",
    category: "Bicycle Shops",
  },
  {
    mcc: "7932",
    category: "Billiard/Pool Establishments",
  },
  {
    mcc: "5551",
    category: "Boat Dealers",
  },
  {
    mcc: "4457",
    category: "Boat Rentals and Leases",
  },
  {
    mcc: "5192",
    category: "Books, Periodicals, and Newspapers",
  },
  {
    mcc: "5942",
    category: "Book Stores",
  },
  {
    mcc: "7933",
    category: "Bowling Alleys",
  },
  {
    mcc: "8244",
    category: "Business/Secretarial Schools",
  },
  {
    mcc: "4131",
    category: "Bus Lines",
  },
  {
    mcc: "7278",
    category: "Buying/Shopping Services",
  },
  {
    mcc: "4899",
    category: "Cable, Satellite, and Other Pay Television and Radio",
  },
  {
    mcc: "5946",
    category: "Camera and Photographic Supply Stores",
  },
  {
    mcc: "5441",
    category: "Candy, Nut, and Confectionery Stores",
  },
  {
    mcc: "5511",
    category: "Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing",
  },
  {
    mcc: "5521",
    category: "Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing",
  },
  {
    mcc: "1750",
    category: "Carpentry Services",
  },
  {
    mcc: "7217",
    category: "Carpet/Upholstery Cleaning",
  },
  {
    mcc: "7512",
    category: "Car Rental Agencies",
  },
  {
    mcc: "7542",
    category: "Car Washes",
  },
  {
    mcc: "5811",
    category: "Caterers",
  },
  {
    mcc: "8398",
    category: "Charitable and Social Service Organizations - Fundraising",
  },
  {
    mcc: "5169",
    category: "Chemicals and Allied Products (Not Elsewhere Classified)",
  },
  {
    mcc: "5641",
    category: "Chidrens and Infants Wear Stores",
  },
  {
    mcc: "8351",
    category: "Child Care Services",
  },
  {
    mcc: "8049",
    category: "Chiropodists, Podiatrists",
  },
  {
    mcc: "8041",
    category: "Chiropractors",
  },
  {
    mcc: "5993",
    category: "Cigar Stores and Stands",
  },
  {
    mcc: "8641",
    category: "Civic, Social, Fraternal Associations",
  },
  {
    mcc: "7349",
    category: "Cleaning and Maintenance",
  },
  {
    mcc: "7296",
    category: "Clothing Rental",
  },
  {
    mcc: "8220",
    category: "Colleges, Universities",
  },
  {
    mcc: "5046",
    category: "Commercial Equipment (Not Elsewhere Classified)",
  },
  {
    mcc: "5139",
    category: "Commercial Footwear",
  },
  {
    mcc: "7333",
    category: "Commercial Photography, Art and Graphics",
  },
  {
    mcc: "4111",
    category: "Commuter Transport, Ferries",
  },
  {
    mcc: "4816",
    category: "Computer Network Services",
  },
  {
    mcc: "7372",
    category: "Computer Programming",
  },
  {
    mcc: "7379",
    category: "Computer Repair",
  },
  {
    mcc: "5734",
    category: "Computer Software Stores",
  },
  {
    mcc: "5045",
    category: "Computers, Peripherals, and Software",
  },
  {
    mcc: "1771",
    category: "Concrete Work Services",
  },
  {
    mcc: "5039",
    category: "Construction Materials (Not Elsewhere Classified)",
  },
  {
    mcc: "7392",
    category: "Consulting, Public Relations",
  },
  {
    mcc: "8241",
    category: "Correspondence Schools",
  },
  {
    mcc: "5977",
    category: "Cosmetic Stores",
  },
  {
    mcc: "7277",
    category: "Counseling Services",
  },
  {
    mcc: "7997",
    category: "Country Clubs",
  },
  {
    mcc: "4215",
    category: "Courier Services",
  },
  {
    mcc: "9211",
    category: "Court Costs, Including Alimony and Child Support - Courts of Law",
  },
  {
    mcc: "7321",
    category: "Credit Reporting Agencies",
  },
  {
    mcc: "4411",
    category: "Cruise Lines",
  },
  {
    mcc: "5451",
    category: "Dairy Products Stores",
  },
  {
    mcc: "7911",
    category: "Dance Hall, Studios, Schools",
  },
  {
    mcc: "7273",
    category: "Dating/Escort Services",
  },
  {
    mcc: "8021",
    category: "Dentists, Orthodontists",
  },
  {
    mcc: "5311",
    category: "Department Stores",
  },
  {
    mcc: "7393",
    category: "Detective Agencies",
  },
  {
    mcc: "5964",
    category: "Direct Marketing - Catalog Merchant",
  },
  {
    mcc: "5965",
    category: "Direct Marketing - Combination Catalog and Retail Merchant",
  },
  {
    mcc: "5967",
    category: "Direct Marketing - Inbound Telemarketing",
  },
  {
    mcc: "5960",
    category: "Direct Marketing - Insurance Services",
  },
  {
    mcc: "5969",
    category: "Direct Marketing - Other",
  },
  {
    mcc: "5966",
    category: "Direct Marketing - Outbound Telemarketing",
  },
  {
    mcc: "5968",
    category: "Direct Marketing - Subscription",
  },
  {
    mcc: "5962",
    category: "Direct Marketing - Travel",
  },
  {
    mcc: "5310",
    category: "Discount Stores",
  },
  {
    mcc: "8011",
    category: "Doctors",
  },
  {
    mcc: "5963",
    category: "Door-To-Door Sales",
  },
  {
    mcc: "5714",
    category: "Drapery, Window Covering, and Upholstery Stores",
  },
  {
    mcc: "5813",
    category: "Drinking Places",
  },
  {
    mcc: "5122",
    category: "Drugs, Drug Proprietaries, and Druggist Sundries",
  },
  {
    mcc: "5912",
    category: "Drug Stores and Pharmacies",
  },
  {
    mcc: "7216",
    category: "Dry Cleaners",
  },
  {
    mcc: "5099",
    category: "Durable Goods (Not Elsewhere Classified)",
  },
  {
    mcc: "5309",
    category: "Duty Free Stores",
  },
  {
    mcc: "5812",
    category: "Eating Places, Restaurants",
  },
  {
    mcc: "8299",
    category: "Educational Services",
  },
  {
    mcc: "5065",
    category: "Electrical Parts and Equipment",
  },
  {
    mcc: "1731",
    category: "Electrical Services",
  },
  {
    mcc: "5997",
    category: "Electric Razor Stores",
  },
  {
    mcc: "7622",
    category: "Electronics Repair Shops",
  },
  {
    mcc: "5732",
    category: "Electronics Stores",
  },
  {
    mcc: "8211",
    category: "Elementary, Secondary Schools",
  },
  {
    mcc: "7361",
    category: "Employment/Temp Agencies",
  },
  {
    mcc: "7394",
    category: "Equipment Rental",
  },
  {
    mcc: "7342",
    category: "Exterminating Services",
  },
  {
    mcc: "5651",
    category: "Family Clothing Stores",
  },
  {
    mcc: "5814",
    category: "Fast Food Restaurants",
  },
  {
    mcc: "6012",
    category: "Financial Institutions",
  },
  {
    mcc: "9222",
    category: "Fines - Government Administrative Entities",
  },
  {
    mcc: "5718",
    category: "Fireplace, Fireplace Screens, and Accessories Stores",
  },
  {
    mcc: "5713",
    category: "Floor Covering Stores",
  },
  {
    mcc: "5992",
    category: "Florists",
  },
  {
    mcc: "5193",
    category: "Florists Supplies, Nursery Stock, and Flowers",
  },
  {
    mcc: "5422",
    category: "Freezer and Locker Meat Provisioners",
  },
  {
    mcc: "5983",
    category: "Fuel Dealers (Non Automotive)",
  },
  {
    mcc: "7261",
    category: "Funeral Services, Crematories",
  },
  {
    mcc: "5712",
    category: "Furniture, Home Furnishings, and Equipment Stores, Except Appliance",
  },
  {
    mcc: "7641",
    category: "Furniture Repair, Refinishing",
  },
  {
    mcc: "5681",
    category: "Furriers and Fur Shops",
  },
  {
    mcc: "1520",
    category: "General Services",
  },
  {
    mcc: "5947",
    category: "Gift, Card, Novelty, and Souvenir Shops",
  },
  {
    mcc: "5231",
    category: "Glass, Paint, and Wallpaper Stores",
  },
  {
    mcc: "5950",
    category: "Glassware, Crystal Stores",
  },
  {
    mcc: "7992",
    category: "Golf Courses - Public",
  },
  {
    mcc: "9399",
    category: "Government Services (Not Elsewhere Classified)",
  },
  {
    mcc: "5411",
    category: "Grocery Stores, Supermarkets",
  },
  {
    mcc: "5072",
    category: "Hardware, Equipment, and Supplies",
  },
  {
    mcc: "5251",
    category: "Hardware Stores",
  },
  {
    mcc: "7298",
    category: "Health and Beauty Spas",
  },
  {
    mcc: "5975",
    category: "Hearing Aids Sales and Supplies",
  },
  {
    mcc: "1711",
    category: "Heating, Plumbing, A/C",
  },
  {
    mcc: "5945",
    category: "Hobby, Toy, and Game Shops",
  },
  {
    mcc: "5200",
    category: "Home Supply Warehouse Stores",
  },
  {
    mcc: "8062",
    category: "Hospitals",
  },
  {
    mcc: "7011",
    category: "Hotels, Motels, and Resorts",
  },
  {
    mcc: "5722",
    category: "Household Appliance Stores",
  },
  {
    mcc: "5085",
    category: "Industrial Supplies (Not Elsewhere Classified)",
  },
  {
    mcc: "7375",
    category: "Information Retrieval Services",
  },
  {
    mcc: "6399",
    category: "Insurance - Default",
  },
  {
    mcc: "6300",
    category: "Insurance Underwriting, Premiums",
  },
  {
    mcc: "9950",
    category: "Intra-Company Purchases",
  },
  {
    mcc: "5944",
    category: "Jewelry Stores, Watches, Clocks, and Silverware Stores",
  },
  {
    mcc: "0780",
    category: "Landscaping Services",
  },
  {
    mcc: "7211",
    category: "Laundries",
  },
  {
    mcc: "7210",
    category: "Laundry, Cleaning Services",
  },
  {
    mcc: "8111",
    category: "Legal Services, Attorneys",
  },
  {
    mcc: "5948",
    category: "Luggage and Leather Goods Stores",
  },
  {
    mcc: "5211",
    category: "Lumber, Building Materials Stores",
  },
  {
    mcc: "6010",
    category: "Manual Cash Disburse",
  },
  {
    mcc: "4468",
    category: "Marinas, Service and Supplies",
  },
  {
    mcc: "1740",
    category: "Masonry, Stonework, and Plaster",
  },
  {
    mcc: "7297",
    category: "Massage Parlors",
  },
  {
    mcc: "8071",
    category: "Medical and Dental Labs",
  },
  {
    mcc: "5047",
    category: "Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies",
  },
  {
    mcc: "8099",
    category: "Medical Services",
  },
  {
    mcc: "8699",
    category: "Membership Organizations",
  },
  {
    mcc: "5611",
    category: "Mens and Boys Clothing and Accessories Stores",
  },
  {
    mcc: "5691",
    category: "Mens, Womens Clothing Stores",
  },
  {
    mcc: "5051",
    category: "Metal Service Centers",
  },
  {
    mcc: "5699",
    category: "Miscellaneous Apparel and Accessory Shops",
  },
  {
    mcc: "5599",
    category: "Miscellaneous Auto Dealers",
  },
  {
    mcc: "7399",
    category: "Miscellaneous Business Services",
  },
  {
    mcc: "5499",
    category: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
  },
  {
    mcc: "5399",
    category: "Miscellaneous General Merchandise",
  },
  {
    mcc: "7299",
    category: "Miscellaneous General Services",
  },
  {
    mcc: "5719",
    category: "Miscellaneous Home Furnishing Specialty Stores",
  },
  {
    mcc: "2741",
    category: "Miscellaneous Publishing and Printing",
  },
  {
    mcc: "7999",
    category: "Miscellaneous Recreation Services",
  },
  {
    mcc: "7699",
    category: "Miscellaneous Repair Shops",
  },
  {
    mcc: "5999",
    category: "Miscellaneous Specialty Retail",
  },
  {
    mcc: "5271",
    category: "Mobile Home Dealers",
  },
  {
    mcc: "7832",
    category: "Motion Picture Theaters",
  },
  {
    mcc: "5571",
    category: "Motorcycle Shops and Dealers",
  },
  {
    mcc: "5561",
    category: "Motorcycle Shops, Dealers",
  },
  {
    mcc: "4214",
    category:
      "Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and, Local Delivery Services",
  },
  {
    mcc: "5592",
    category: "Motor Homes Dealers",
  },
  {
    mcc: "5013",
    category: "Motor Vehicle Supplies and New Parts",
  },
  {
    mcc: "5733",
    category: "Music Stores-Musical Instruments, Pianos, and Sheet Music",
  },
  {
    mcc: "5994",
    category: "News Dealers and Newsstands",
  },
  {
    mcc: "5199",
    category: "Nondurable Goods (Not Elsewhere Classified)",
  },
  {
    mcc: "6051",
    category: "Non-FI, Money Orders",
  },
  {
    mcc: "6540",
    category: "Non-FI, Stored Value Card Purchase/Load",
  },
  {
    mcc: "5261",
    category: "Nurseries, Lawn and Garden Supply Stores",
  },
  {
    mcc: "8050",
    category: "Nursing/Personal Care",
  },
  {
    mcc: "5021",
    category: "Office and Commercial Furniture",
  },
  {
    mcc: "8043",
    category: "Opticians, Eyeglasses",
  },
  {
    mcc: "8042",
    category: "Optometrists, Ophthalmologist",
  },
  {
    mcc: "5976",
    category: "Orthopedic Goods - Prosthetic Devices",
  },
  {
    mcc: "8031",
    category: "Osteopaths",
  },
  {
    mcc: "5921",
    category: "Package Stores-Beer, Wine, and Liquor",
  },
  {
    mcc: "5198",
    category: "Paints, Varnishes, and Supplies",
  },
  {
    mcc: "7523",
    category: "Parking Lots, Garages",
  },
  {
    mcc: "4112",
    category: "Passenger Railways",
  },
  {
    mcc: "5933",
    category: "Pawn Shops",
  },
  {
    mcc: "5172",
    category: "Petroleum and Petroleum Products",
  },
  {
    mcc: "5995",
    category: "Pet Shops, Pet Food, and Supplies",
  },
  {
    mcc: "7395",
    category: "Photo Developing",
  },
  {
    mcc: "5044",
    category: "Photographic, Photocopy, Microfilm Equipment, and Supplies",
  },
  {
    mcc: "7221",
    category: "Photographic Studios",
  },
  {
    mcc: "7829",
    category: "Picture/Video Production",
  },
  {
    mcc: "5131",
    category: "Piece Goods, Notions, and Other Dry Goods",
  },
  {
    mcc: "5074",
    category: "Plumbing, Heating Equipment, and Supplies",
  },
  {
    mcc: "8651",
    category: "Political Organizations",
  },
  {
    mcc: "9402",
    category: "Postal Services - Government Only",
  },
  {
    mcc: "5094",
    category: "Precious Stones and Metals, Watches and Jewelry",
  },
  {
    mcc: "8999",
    category: "Professional Services",
  },
  {
    mcc: "4225",
    category:
      "Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage",
  },
  {
    mcc: "7338",
    category: "Quick Copy, Repro, and Blueprint",
  },
  {
    mcc: "4011",
    category: "Railroads",
  },
  {
    mcc: "6513",
    category: "Real Estate Agents and Managers - Rentals",
  },
  {
    mcc: "5735",
    category: "Record Stores",
  },
  {
    mcc: "7519",
    category: "Recreational Vehicle Rentals",
  },
  {
    mcc: "5973",
    category: "Religious Goods Stores",
  },
  {
    mcc: "8661",
    category: "Religious Organizations",
  },
  {
    mcc: "1761",
    category: "Roofing/Siding, Sheet Metal",
  },
  {
    mcc: "7339",
    category: "Secretarial Support Services",
  },
  {
    mcc: "6211",
    category: "Security Brokers/Dealers",
  },
  {
    mcc: "5541",
    category: "Service Stations",
  },
  {
    mcc: "5949",
    category: "Sewing, Needlework, Fabric, and Piece Goods Stores",
  },
  {
    mcc: "7251",
    category: "Shoe Repair/Hat Cleaning",
  },
  {
    mcc: "5661",
    category: "Shoe Stores",
  },
  {
    mcc: "7629",
    category: "Small Appliance Repair",
  },
  {
    mcc: "5598",
    category: "Snowmobile Dealers",
  },
  {
    mcc: "1799",
    category: "Special Trade Services",
  },
  {
    mcc: "2842",
    category: "Specialty Cleaning",
  },
  {
    mcc: "5941",
    category: "Sporting Goods Stores",
  },
  {
    mcc: "7032",
    category: "Sporting/Recreation Camps",
  },
  {
    mcc: "5655",
    category: "Sports and Riding Apparel Stores",
  },
  {
    mcc: "7941",
    category: "Sports Clubs/Fields",
  },
  {
    mcc: "5972",
    category: "Stamp and Coin Stores",
  },
  {
    mcc: "5111",
    category: "Stationary, Office Supplies, Printing and Writing Paper",
  },
  {
    mcc: "5943",
    category: "Stationery Stores, Office, and School Supply Stores",
  },
  {
    mcc: "5996",
    category: "Swimming Pools Sales",
  },
  {
    mcc: "5697",
    category: "Tailors, Alterations",
  },
  {
    mcc: "4121",
    category: "Taxicabs/Limousines",
  },
  {
    mcc: "9311",
    category: "Tax Payments - Government Agencies",
  },
  {
    mcc: "7276",
    category: "Tax Preparation Services",
  },
  {
    mcc: "4812",
    category: "Telecommunication Equipment and Telephone Sales",
  },
  {
    mcc: "4814",
    category: "Telecommunication Services",
  },
  {
    mcc: "4821",
    category: "Telegraph Services",
  },
  {
    mcc: "5998",
    category: "Tent and Awning Shops",
  },
  {
    mcc: "8734",
    category: "Testing Laboratories",
  },
  {
    mcc: "7922",
    category: "Theatrical Ticket Agencies",
  },
  {
    mcc: "7012",
    category: "Timeshares",
  },
  {
    mcc: "7534",
    category: "Tire Retreading and Repair",
  },
  {
    mcc: "4784",
    category: "Tolls/Bridge Fees",
  },
  {
    mcc: "7991",
    category: "Tourist Attractions and Exhibits",
  },
  {
    mcc: "7549",
    category: "Towing Services",
  },
  {
    mcc: "7033",
    category: "Trailer Parks, Campgrounds",
  },
  {
    mcc: "4789",
    category: "Transportation Services (Not Elsewhere Classified)",
  },
  {
    mcc: "4722",
    category: "Travel Agencies, Tour Operators",
  },
  {
    mcc: "7511",
    category: "Truck StopIteration",
  },
  {
    mcc: "7513",
    category: "Truck/Utility Trailer Rentals",
  },
  {
    mcc: "4723",
    category: "TUI Travel - Germany",
  },
  {
    mcc: "2791",
    category: "Typesetting, Plate Making, and Related Services",
  },
  {
    mcc: "5978",
    category: "Typewriter Stores",
  },
  {
    mcc: "5137",
    category: "Uniforms, Commercial Clothing",
  },
  {
    mcc: "5931",
    category: "Used Merchandise and Secondhand Stores",
  },
  {
    mcc: "9405",
    category: "U.S. Federal Government Agencies or Departments",
  },
  {
    mcc: "4900",
    category: "Utilities",
  },
  {
    mcc: "5331",
    category: "Variety Stores",
  },
  {
    mcc: "0742",
    category: "Veterinary Services",
  },
  {
    mcc: "7993",
    category: "Video Amusement Game Supplies",
  },
  {
    mcc: "7994",
    category: "Video Game Arcades",
  },
  {
    mcc: "7841",
    category: "Video Tape Rental Stores",
  },
  {
    mcc: "8249",
    category: "Vocational/Trade Schools",
  },
  {
    mcc: "7631",
    category: "Watch/Jewelry Repair",
  },
  {
    mcc: "7692",
    category: "Welding Repair",
  },
  {
    mcc: "5300",
    category: "Wholesale Clubs",
  },
  {
    mcc: "5698",
    category: "Wig and Toupee Stores",
  },
  {
    mcc: "4829",
    category: "Wires, Money Orders",
  },
  {
    mcc: "5631",
    category: "Womens Accessory and Specialty Shops",
  },
  {
    mcc: "5621",
    category: "Womens Ready-To-Wear Stores",
  },
  {
    mcc: "5935",
    category: "Wrecking and Salvage Yards",
  },
];
