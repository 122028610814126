import React, { useState } from "react";
import { Form, Formik, FormikErrors } from "formik";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cardTransactionSimulationFormSchema } from "../schemas/cardTransactionSimulationFormSchema";
import { Checkbox } from "./Checkbox";
import { Input } from "./Input";
import { Select } from "./Select";
import { TextArea } from "./TextArea";
import { merchantCategoryCodes } from "../data/merchantCategoryCodes";
import {
  webhookTypeOptions,
  galileoCardStatuses,
  eventTypes,
  initialValues,
  webhookServerOptions,
} from "../data/formData";
import {
  CardTransactionSimulationResponseTable,
  SimulationResponse,
} from "./CardTransactionSimulationResponseTable";
import { AuthModal } from "./modals/AuthModal";
import {
  AuthorizationWebhookType,
  GalileoResponseCode,
  SettlementWebhookType,
  WebhookType,
  SimulationType,
  WebhookServer,
  RDFProcessingStatus,
  AccountEventWebhookType,
} from "../data/enums";
import { JSONPayloadFormatter } from "./JSONPayloadFormatter";
import { RegularSimulationRequest, SimulationWithJSONPayloadRequest } from "../data/types";

export interface FormValues {
  webhookServer: string;
  webhookType: string;
  eventType: string;
  authId: string;
  originalAuthId: string;
  amount: string;
  remainingAmount: string;
  description: string;
  merchantName: string;
  merchantCategoryCode: string;
  international: boolean;
  cardId: string;
  galileoCardId: string;
  paymentReferenceNumber: string;
  globalCardId: string;
  cardStatus: string;
  additionalOptions: boolean;
  responseCodeObject: string;
  responseCode: string;
  postOnlyToRDF: boolean;
}

export const simulate = async (
  simulationType: SimulationType,
  values: FormValues,
): Promise<AxiosResponse<SimulationResponse>> => {
  const url = process.env.REACT_APP_CARD_SIMULATION_URL ?? "";
  const headers = { "Content-Type": "application/json" };
  const data = createPayload(simulationType, values);
  let simulationResponse;

  if (values.postOnlyToRDF) {
    data.webhook_type = WebhookType.RDF_POST;
    simulationResponse = await axios.post(url, data, { headers: headers });
  } else {
    simulationResponse = await axios.post(url, data, { headers: headers });
    if (
      [WebhookType.SETTLEMENT.toString(), WebhookType.TRANSACTION_EVENT.toString()].includes(
        data.webhook_type ?? "",
      )
    ) {
      postTransactionToRDF(data, url);
    }
  }

  return simulationResponse;
};

export function postTransactionToRDF(
  data: RegularSimulationRequest | SimulationWithJSONPayloadRequest,
  url: string,
) {
  if (data.simulation_type == SimulationType.GENERATE_PAYLOAD) return;

  data.webhook_type = WebhookType.RDF_POST;

  axios
    .post(url, data, { headers: { "Content-Type": "application/json" } })
    .then(response => {
      displayRDFToastBasedOnStatus(response.data.rdf_post_status);
    })
    .catch(() => {
      toast.error("Unknown error occurred during RDF post", {
        autoClose: 5000,
        closeButton: false,
        hideProgressBar: true,
      });
    });
}

function displayRDFToastBasedOnStatus(processing_status: RDFProcessingStatus): void {
  const toastConfig = {
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: true,
  };

  switch (processing_status) {
    case RDFProcessingStatus.SUCCESS:
      toast.success("RDF post successful", toastConfig);
      break;
    case RDFProcessingStatus.FAILED:
      toast.error("RDF post failed", toastConfig);
      break;
    case RDFProcessingStatus.SKIPPED:
      toast.info("RDF post skipped", toastConfig);
      break;
    default:
      toast.warn("RDF post status unknown", toastConfig);
  }
}

function createPayload(simulationType: SimulationType, values: FormValues) {
  return {
    simulation_type: simulationType,
    webhook_server: isBlank(values.webhookServer) ? null : values.webhookServer,
    webhook_type: isBlank(values.webhookType) ? null : values.webhookType,
    webhook_payload: {
      event_type: isBlank(values.eventType) ? null : values.eventType,
      event_payload: {
        original_auth_id: isBlank(values.originalAuthId) ? null : values.originalAuthId,
        auth_id: isBlank(values.authId) ? null : values.authId,
        amount: isBlank(values.amount) ? null : values.amount,
        remaining_amount: isBlank(values.remainingAmount) ? null : values.remainingAmount,
        description: isBlank(values.description) ? null : values.description,
        merchant_name: isBlank(values.merchantName) ? null : values.merchantName,
        mcc: isBlank(values.merchantCategoryCode) ? null : values.merchantCategoryCode,
        international: values.international,
        card_id: isBlank(values.cardId) ? null : values.cardId,
        galileo_card_id: isBlank(values.galileoCardId) ? null : values.galileoCardId,
        prn: isBlank(values.paymentReferenceNumber) ? null : values.paymentReferenceNumber,
        global_card_id: isBlank(values.globalCardId) ? null : values.globalCardId,
        card_status: isBlank(values.cardStatus) ? null : values.cardStatus,
        response_code: isBlank(values.responseCode) ? null : values.responseCode,
        response_code_object: isBlank(values.responseCodeObject) ? null : values.responseCodeObject,
        timestamp: new Date().toISOString(),
      },
    },
  };
}

const fieldDisableConditions = (values: FormValues) => {
  return {
    authId: [
      WebhookType.AUTHORIZATION.toString(),
      WebhookType.TRANSACTION_EVENT.toString(),
      WebhookType.ACCOUNT_EVENT.toString(),
    ].includes(values.webhookType),
    originalAuthId: !(
      isBlank(values.webhookType) ||
      [
        AuthorizationWebhookType.INCREMENTAL.toString(),
        AuthorizationWebhookType.REVERSAL.toString(),
        AuthorizationWebhookType.ADVICE.toString(),
        SettlementWebhookType.INCREMENTAL_CLEARING.toString(),
      ].includes(values.eventType)
    ),
    merchantName: [
      WebhookType.TRANSACTION_EVENT.toString(),
      WebhookType.ACCOUNT_EVENT.toString(),
    ].includes(values.webhookType),
    cardId: values.eventType == AccountEventWebhookType.CARD_SHIPPED.toString(),
    merchantCategoryCode: [
      WebhookType.TRANSACTION_EVENT.toString(),
      WebhookType.ACCOUNT_EVENT.toString(),
    ].includes(values.webhookType),
    globalCardId: values.webhookServer !== WebhookServer.CARD_SERVICE.toString(),
    cardStatus: [
      WebhookType.SETTLEMENT.toString(),
      WebhookType.AUTHORIZATION_EVENT.toString(),
      WebhookType.TRANSACTION_EVENT.toString(),
      WebhookType.ACCOUNT_EVENT.toString(),
    ].includes(values.webhookType),
    amount: [WebhookType.ACCOUNT_EVENT.toString()].includes(values.webhookType),
  };
};

const fieldCleanupConditions = (e: React.ChangeEvent<HTMLSelectElement>) => {
  return {
    authId: [
      WebhookType.AUTHORIZATION.toString(),
      WebhookType.TRANSACTION_EVENT.toString(),
    ].includes(e.target.value),
    originalAuthId: !(
      isBlank(e.target.value) ||
      [
        AuthorizationWebhookType.INCREMENTAL.toString(),
        AuthorizationWebhookType.REVERSAL.toString(),
        AuthorizationWebhookType.ADVICE.toString(),
        SettlementWebhookType.INCREMENTAL_CLEARING.toString(),
      ].includes(e.target.value)
    ),
    merchantName: [WebhookType.TRANSACTION_EVENT.toString()].includes(e.target.value),
    merchantCategoryCode: [WebhookType.TRANSACTION_EVENT.toString()].includes(e.target.value),
    globalCardId: e.target.value !== WebhookServer.CARD_SERVICE.toString(),
  };
};

const isBlank = (inputValue: string) => !inputValue.trim();

export const CardTransactionSimulationForm = () => {
  const [simulationResponse, setSimulationResponse] = useState<SimulationResponse>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [webhookTypeForJsonFormatter, setWebhookTypeForJsonFormatter] = useState("");
  const [jsonPayload, setJsonPayload] = useState({});

  const onJsonPayloadUpdate = (values: object) => {
    setJsonPayload(values);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmitForm = async (values: FormValues) => {
    try {
      const { data } = await simulate(SimulationType.FULL_CYCLE, values);
      setSimulationResponse(data);
      setJsonPayload({});
      const responseCode = data.authorization?.response?.response_code;
      if (
        responseCode === GalileoResponseCode.SUCCESS &&
        (values.eventType === AuthorizationWebhookType.AUTHORIZATION ||
          values.eventType === AuthorizationWebhookType.ADVICE)
      ) {
        openModal();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error occurred during simulation.", {
        autoClose: 5000,
        closeButton: false,
        hideProgressBar: true,
      });
    }
  };

  const generateJsonPayload = async (values: FormValues) => {
    const { data } = await simulate(SimulationType.GENERATE_PAYLOAD, values);
    setSimulationResponse(data);
    setWebhookTypeForJsonFormatter(values.webhookType);
    setJsonPayload(JSON.parse(data.json_payload ?? "{}"));
  };

  const validate = (values: FormValues) => {
    const disabledFields = fieldDisableConditions(values);
    const errors: FormikErrors<FormValues> = {};

    if (values.eventType === AccountEventWebhookType.CARD_SHIPPED.toString()) {
      const requiredFieldsError =
        "Both Galileo card ID and Payment reference number are required fields";
      if (isBlank(values.paymentReferenceNumber) || isBlank(values.galileoCardId)) {
        errors.galileoCardId = requiredFieldsError;
        errors.paymentReferenceNumber = requiredFieldsError;
      }
    } else {
      const requiredFieldsError =
        "At least one of the fields: Card ID, Galileo card ID, Payment reference number and Global Card ID is required";
      const cardInfoError =
        isBlank(values.cardId) &&
        isBlank(values.galileoCardId) &&
        isBlank(values.paymentReferenceNumber) &&
        isBlank(values.globalCardId);
      if (cardInfoError) {
        errors.cardId = requiredFieldsError;
        errors.galileoCardId = requiredFieldsError;
        errors.paymentReferenceNumber = requiredFieldsError;
      }
    }

    const requiredFieldError = "Required";
    if (!disabledFields.originalAuthId && isBlank(values.originalAuthId)) {
      errors.originalAuthId = requiredFieldError;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={cardTransactionSimulationFormSchema}
        validate={validate}
        onSubmit={onSubmitForm}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          validateForm,
          setSubmitting,
          isValid,
          isValidating,
        }) => {
          const handleModalSubmit = async () => {
            closeModal();
            const newValues: FormValues = { ...values };
            setFieldValue("webhookType", WebhookType.SETTLEMENT);
            newValues.webhookType = WebhookType.SETTLEMENT;
            setFieldValue("eventType", SettlementWebhookType.SETTLEMENT);
            newValues.eventType = SettlementWebhookType.SETTLEMENT;
            setFieldValue("authId", simulationResponse?.authorization?.auth_id);
            newValues.authId = simulationResponse?.authorization?.auth_id ?? "";
            setFieldValue("originalAuthId", "");
            setSubmitting(true);
            await onSubmitForm(newValues);
            setSubmitting(false);
          };

          const handleGenerateJsonPayload = async () => {
            await validateForm();
            if (isValid) {
              setSubmitting(true);
              try {
                await generateJsonPayload(values);
              } catch (error) {
                console.log(error);
              } finally {
                setSubmitting(false);
              }
            }
          };

          const disabledFields = fieldDisableConditions(values);

          const onChangeHelper = (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (fieldCleanupConditions(e).authId) setFieldValue("authId", "");
            if (fieldCleanupConditions(e).originalAuthId) setFieldValue("originalAuthId", "");
            if (fieldCleanupConditions(e).merchantName) setFieldValue("merchantName", "");
            if (fieldCleanupConditions(e).merchantCategoryCode)
              setFieldValue("merchantCategoryCode", "");
            if (fieldCleanupConditions(e).globalCardId) setFieldValue("globalCardId", "");
            validateForm();
          };

          return (
            <>
              <Form>
                <AuthModal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  onSubmit={handleModalSubmit}
                />
                <Select label="Webhook server" name="webhookServer" onChange={onChangeHelper}>
                  <option value="">Select webhook server</option>
                  {webhookServerOptions.map(({ key, value }) => (
                    <option value={value} key={key}>
                      {key}
                    </option>
                  ))}
                </Select>
                <Select label="Webhook type" name="webhookType" onChange={onChangeHelper}>
                  <option value="">Select webhook type</option>
                  {webhookTypeOptions.map(({ key, value }) => (
                    <option value={value} key={key}>
                      {key}
                    </option>
                  ))}
                </Select>
                <Select label="Event type" name="eventType" onChange={onChangeHelper}>
                  <option value="">Select event type</option>
                  {values.webhookType &&
                    eventTypes[values.webhookType].map(({ key, value }) => (
                      <option value={value} key={key}>
                        {key}
                      </option>
                    ))}
                </Select>
                <Input
                  label="Authorization ID"
                  name="authId"
                  type="text"
                  placeholder="Enter authorization ID"
                  disabled={disabledFields.authId}
                />
                <Input
                  label="Original authorization ID"
                  name="originalAuthId"
                  type="text"
                  placeholder="Enter original authorization ID"
                  disabled={disabledFields.originalAuthId}
                />
                <Input
                  label="Amount"
                  name="amount"
                  type="text"
                  placeholder="Enter amount"
                  disabled={disabledFields.amount}
                />
                {values.eventType === SettlementWebhookType.INCREMENTAL_CLEARING && (
                  <Input
                    label="Remaining Amount"
                    name="remainingAmount"
                    type="text"
                    placeholder="Enter remaining amount"
                  />
                )}
                {values.webhookType === WebhookType.TRANSACTION_EVENT && (
                  <Input
                    label="Description"
                    name="description"
                    type="text"
                    placeholder="Enter description"
                  />
                )}
                <Input
                  label="Merchant name"
                  name="merchantName"
                  type="text"
                  placeholder="Enter merchant name"
                  disabled={disabledFields.merchantName}
                />
                <Select
                  label="Merchant category code"
                  name="merchantCategoryCode"
                  disabled={disabledFields.merchantCategoryCode}
                >
                  <option value="">Select merchant category code</option>
                  {merchantCategoryCodes.map(({ mcc, category }) => (
                    <option value={mcc} key={mcc}>
                      {category} - {mcc}
                    </option>
                  ))}
                </Select>
                <Checkbox label="International" name="international" />
                <Input
                  label="Card ID"
                  name="cardId"
                  type="text"
                  placeholder="Enter card ID"
                  disabled={disabledFields.cardId}
                />
                <Input
                  label="Global Card ID"
                  name="globalCardId"
                  type="text"
                  placeholder="Enter global card ID"
                  disabled={disabledFields.globalCardId}
                  onChange={onChangeHelper}
                />
                <Input
                  label="Galileo card ID"
                  name="galileoCardId"
                  type="text"
                  placeholder="Enter Galileo card ID"
                />
                <Input
                  label="Payment reference number"
                  name="paymentReferenceNumber"
                  type="text"
                  placeholder="Enter payment reference number"
                />
                <Select label="Card status" name="cardStatus" disabled={disabledFields.cardStatus}>
                  <option value="">Select card status</option>
                  {galileoCardStatuses.map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                </Select>
                <Checkbox label="Post only to RDF" name="postOnlyToRDF" />
                <Checkbox label="Show response code options" name="additionalOptions" />
                {values.additionalOptions && (
                  <>
                    <TextArea
                      label="Response code object"
                      name="responseCodeObject"
                      placeholder="Enter response code object"
                    />
                    <Input
                      label="Response code"
                      name="responseCode"
                      type="text"
                      placeholder="Enter response code"
                    />
                  </>
                )}

                <button disabled={isSubmitting} type="submit">
                  Simulate
                </button>
                <button
                  type="button"
                  disabled={isSubmitting || isValidating}
                  onClick={handleGenerateJsonPayload}
                >
                  Generate JSON payload
                </button>
              </Form>
              <JSONPayloadFormatter
                webhookServer={values.webhookServer}
                webhookType={webhookTypeForJsonFormatter}
                jsonPayload={jsonPayload}
                postOnlyToRDF={values.postOnlyToRDF}
                handleJsonPayloadUpdate={onJsonPayloadUpdate}
              ></JSONPayloadFormatter>
              <CardTransactionSimulationResponseTable
                simulationProps={simulationResponse}
                authIdProp={values.authId}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};
