import React from "react";
import { useField, FieldInputProps } from "formik";

interface CustomCheckboxProps extends Omit<Partial<FieldInputProps<boolean>>, "value"> {
  name: string;
  label: string;
}

export const Checkbox: React.FC<CustomCheckboxProps> = ({ name, label, ...props }) => {
  const [field, meta] = useField({ name, type: "checkbox", ...props });

  return (
    <>
      <div className="checkbox">
        <input
          {...field}
          {...props}
          type="checkbox"
          className={meta.touched && meta.error ? "input-error" : ""}
        />
        <span>{label}</span>
      </div>

      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </>
  );
};
