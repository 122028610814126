import React from "react";
import { FieldInputProps, useField } from "formik";

interface CustomInputProps extends Partial<FieldInputProps<string>> {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  disabled?: boolean;
}

export const Input: React.FC<CustomInputProps> = ({
  label,
  name,
  type,
  placeholder,
  disabled,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });

  return (
    <>
      <label>{label}</label>
      <input
        {...field}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        className={`${meta.touched && meta.error ? "input-error" : ""} ${
          disabled ? "input-disabled" : ""
        }`.trim()}
      />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </>
  );
};
