import { FormValues } from "../components/CardTransactionSimulationForm";
import {
  WebhookType,
  AuthorizationWebhookType,
  SettlementWebhookType,
  WebhookServer,
  AuthorizationEventWebhookType,
  TransactionEventWebhookType,
  AccountEventWebhookType,
} from "./enums";

interface WebhookTypeOption {
  key: string;
  value: string;
}

export const initialValues: FormValues = {
  webhookServer: "PLEDGE_API",
  webhookType: "",
  eventType: "",
  authId: "",
  originalAuthId: "",
  amount: "",
  remainingAmount: "",
  description: "",
  merchantName: "",
  merchantCategoryCode: "",
  international: false,
  cardId: "",
  galileoCardId: "",
  paymentReferenceNumber: "",
  globalCardId: "",
  cardStatus: "N",
  additionalOptions: false,
  responseCodeObject: "",
  responseCode: "00",
  postOnlyToRDF: false,
};

export const webhookTypeOptions: WebhookTypeOption[] = [
  {
    key: "Authorization",
    value: WebhookType.AUTHORIZATION,
  },
  {
    key: "Settlement",
    value: WebhookType.SETTLEMENT,
  },
  {
    key: "Authorization Event",
    value: WebhookType.AUTHORIZATION_EVENT,
  },
  {
    key: "Transaction Event",
    value: WebhookType.TRANSACTION_EVENT,
  },
  {
    key: "Account Event",
    value: WebhookType.ACCOUNT_EVENT,
  },
];

export const webhookServerOptions: WebhookTypeOption[] = [
  {
    key: "Pledge API",
    value: WebhookServer.PLEDGE_API,
  },
  {
    key: "Card Service",
    value: WebhookServer.CARD_SERVICE,
  },
];

export const galileoCardStatuses: string[] = [
  "A",
  "B",
  "C",
  "D",
  "L",
  "N",
  "O",
  "Q",
  "R",
  "S",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

interface EventType {
  key: string;
  value: string;
}

const authorizationWebhookTypes: EventType[] = [
  {
    key: "Auth",
    value: AuthorizationWebhookType.AUTHORIZATION,
  },
  {
    key: "Preauth",
    value: AuthorizationWebhookType.PREAUTHORIZATION,
  },
  {
    key: "Incremental Auth",
    value: AuthorizationWebhookType.INCREMENTAL,
  },
  {
    key: "Advice",
    value: AuthorizationWebhookType.ADVICE,
  },
  {
    key: "Reversal",
    value: AuthorizationWebhookType.REVERSAL,
  },
  {
    key: "Tokenization",
    value: AuthorizationWebhookType.TOKENIZATION,
  },
];

const settlementWebhookTypes: EventType[] = [
  {
    key: "Settle",
    value: SettlementWebhookType.SETTLEMENT,
  },
  {
    key: "Incremental Clearing",
    value: SettlementWebhookType.INCREMENTAL_CLEARING,
  },
];

const authorizationEventWebhookTypes: EventType[] = [
  {
    key: "Auth Event",
    value: AuthorizationEventWebhookType.AUTH_EVENT,
  },
  {
    key: "Auth API Timeout",
    value: AuthorizationEventWebhookType.AUTH_API_TIMEOUT,
  },
  {
    key: "Auth Failure - Expired",
    value: AuthorizationEventWebhookType.AUTH_EXPIRED,
  },
  {
    key: "Auth Failure - Expired Reversal",
    value: AuthorizationEventWebhookType.AUTH_EXPIRED_REVERSAL,
  },
  {
    key: "Auth Failure - Fallback",
    value: AuthorizationEventWebhookType.AUTH_FALLBACK,
  },
  {
    key: "Auth Denied",
    value: AuthorizationEventWebhookType.AUTH_DENIED,
  },
  {
    key: "Auth Denied - Gas",
    value: AuthorizationEventWebhookType.AUTH_DENIED_GAS,
  },
  {
    key: "Auth Denied - Inactive Card",
    value: AuthorizationEventWebhookType.AUTH_DENIED_INACTIVE_CARD,
  },
  {
    key: "Auth Denied - Invalid PIN",
    value: AuthorizationEventWebhookType.AUTH_DENIED_INVALID_PIN,
  },
  {
    key: "Auth Denied - NSF",
    value: AuthorizationEventWebhookType.AUTH_DENIED_NSF,
  },
  {
    key: "Auth Denied - STIP",
    value: AuthorizationEventWebhookType.AUTH_STIP_INFO,
  },
];

const transactionEventWebhookTypes: EventType[] = [
  {
    key: "Transaction - Adjustment",
    value: TransactionEventWebhookType.TRANSACTION_ADJUSTMENT,
  },
  {
    key: "Transaction - Fee",
    value: TransactionEventWebhookType.TRANSACTION_FEE,
  },
  {
    key: "Transaction - Payment",
    value: TransactionEventWebhookType.TRANSACTION_PAYMENT,
  },
];

const accountEventWebhookTypes: EventType[] = [
  {
    key: "Account - Card Expiry",
    value: AccountEventWebhookType.CARD_EXPIRING,
  },
  {
    key: "Account - Card Shipped",
    value: AccountEventWebhookType.CARD_SHIPPED,
  },
];

export const eventTypes: Record<string, EventType[]> = {
  auth: authorizationWebhookTypes,
  settlement: settlementWebhookTypes,
  authorization_event: authorizationEventWebhookTypes,
  transaction_event: transactionEventWebhookTypes,
  account_event: accountEventWebhookTypes,
};
