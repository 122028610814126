import React, { useState } from "react";
import ReactJson, { InteractionProps } from "react-json-view";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SimulationType, WebhookType } from "../data/enums";
import axios, { AxiosResponse } from "axios";
import {
  CardTransactionSimulationResponseTable,
  SimulationResponse,
} from "./CardTransactionSimulationResponseTable";
import { postTransactionToRDF } from "./CardTransactionSimulationForm";

interface JSONPayloadFormatterProps {
  webhookServer: string;
  webhookType: string;
  jsonPayload: object;
  postOnlyToRDF: boolean;
  handleJsonPayloadUpdate: (newJsonPayload: object) => void;
}

export const JSONPayloadFormatter = (props: JSONPayloadFormatterProps) => {
  const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  if (!props) return null;
  const { webhookServer, webhookType, jsonPayload, postOnlyToRDF } = props;
  if (JSON.stringify(jsonPayload) === "{}") return null;

  const [simulationResponse, setSimulationResponse] = useState<SimulationResponse>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onClick = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await simulate();
      setSimulationResponse(data);
    } catch (error) {
      console.log(error);
      toast.error("Error occurred during simulation.", {
        toastId: "JSONFormatter",
        autoClose: 5000,
        closeButton: false,
        hideProgressBar: true,
      });
    }
    setIsSubmitting(false);
  };

  const simulate = async (): Promise<AxiosResponse<SimulationResponse>> => {
    const url = process.env.REACT_APP_CARD_SIMULATION_URL ?? "";
    const headers = { "Content-Type": "application/json" };
    const data = {
      simulation_type: SimulationType.SIMULATE_WITH_PAYLOAD,
      webhook_server: webhookServer,
      webhook_type: webhookType,
      webhook_payload: JSON.stringify(jsonPayload),
    };
    let simulationResponse;
    if (postOnlyToRDF) {
      data.webhook_type = WebhookType.RDF_POST;
      simulationResponse = await axios.post(url, data, { headers: headers });
    } else {
      simulationResponse = await axios.post(url, data, { headers: headers });
      if (
        [WebhookType.SETTLEMENT.toString(), WebhookType.TRANSACTION_EVENT.toString()].includes(
          data.webhook_type ?? "",
        )
      ) {
        postTransactionToRDF(data, url);
      }
    }

    return simulationResponse;
  };

  const onChange = (values: InteractionProps) => {
    props.handleJsonPayloadUpdate(values.updated_src);
  };

  const hideJSONPayload = () => {
    props.handleJsonPayloadUpdate({});
  };

  return (
    <>
      <ReactJson
        name={null}
        style={styles}
        src={props.jsonPayload}
        onEdit={onChange}
        onAdd={onChange}
        onDelete={onChange}
      />
      <button onClick={onClick} disabled={isSubmitting}>
        Simulate with JSON payload
      </button>
      <button onClick={hideJSONPayload} disabled={isSubmitting}>
        Close JSON payload options
      </button>
      <CardTransactionSimulationResponseTable
        simulationProps={simulationResponse}
      ></CardTransactionSimulationResponseTable>
    </>
  );
};
