export const enum GalileoResponseCode {
  SUCCESS = "00",
  ISSUER_ERROR = "01",
  MCC_BLOCKED = "03",
  PICK_UP = "04",
  DO_NOT_HONOR = "05",
  ERROR = "06",
  PARTIAL_APPROVAL = "10",
  MAX_SYSTEM_AMOUNT_REACHED = "13",
  INVALID_PAN = "14",
  FORMAT_ERROR = "30",
  LOST_FRAUD_PICK_UP = "41",
  STOLEN_FRAUD_PICK_UP = "43",
  INSUFFICIENT_FUNDS = "51",
  CARD_EXPIRED = "54",
  INVALID_PIN = "55",
  TRANSACTION_NOT_PERMITTED_TO_CARDHOLDER = "57",
  SUSPECTED_FRAUD = "59",
  EXCEEDS_AMOUNT_LIMIT = "61",
  CARD_FROZEN = "62",
  SECURITY_VIOLATION = "63",
  EXCEEDS_COUNT_LIMIT = "65",
  PIN_COUNT_EXCEEDED = "75",
  CARD_RESTRICTED = "78",
  AVS_ONLY = "85",
  PARTIAL_APPROVAL_POS_ONLY = "87",
  SYSTEM_ERROR = "96",
  RHO_INTERNAL_ERROR = "RHO_96",
  INVALID_CVV2 = "N7",
}

export const enum EventResponseCode {
  SUCCESS = "0",
  PARAMETERS_DO_NOT_PASS_VALIDATION = "1",
  CARDHOLDER_ACCOUNT_NOT_IN_SYSTEM = "2",
  GENERAL_SYSTEM_FAILURE = "3",
  AUTHENTICATION_FAILED = "4",
  NOT_READY_TO_ACCEPT_MESSAGES = "5",
}

export const enum WebhookType {
  AUTHORIZATION = "auth",
  SETTLEMENT = "settlement",
  AUTHORIZATION_EVENT = "authorization_event",
  TRANSACTION_EVENT = "transaction_event",
  ACCOUNT_EVENT = "account_event",
  RDF_POST = "rdf_post",
}

export const enum WebhookServer {
  PLEDGE_API = "PLEDGE_API",
  CARD_SERVICE = "CARD_SERVICE",
}

export const enum AuthorizationWebhookType {
  AUTHORIZATION = "auth",
  PREAUTHORIZATION = "preauth",
  INCREMENTAL = "incremental-auth",
  ADVICE = "advice",
  REVERSAL = "reversal",
  TOKENIZATION = "tokenization",
}

export const enum SettlementWebhookType {
  SETTLEMENT = "settlement",
  INCREMENTAL_CLEARING = "incremental-clearing",
}

export const enum AuthorizationEventWebhookType {
  AUTH_EVENT = "auth-event",
  AUTH_API_TIMEOUT = "auth-api-timeout",
  AUTH_EXPIRED = "auth-expired",
  AUTH_EXPIRED_REVERSAL = "auth-expired-reversal",
  AUTH_FALLBACK = "auth-fallback",
  AUTH_DENIED = "auth-denied",
  AUTH_DENIED_GAS = "auth-denied-gas",
  AUTH_DENIED_INACTIVE_CARD = "auth-denied-inactive-card",
  AUTH_DENIED_INVALID_PIN = "auth-denied-invalid-pin",
  AUTH_DENIED_NSF = "auth-denied-nsf",
  AUTH_STIP_INFO = "auth-stip-info",
}

export const enum TransactionEventWebhookType {
  TRANSACTION_ADJUSTMENT = "transaction-adj",
  TRANSACTION_FEE = "transaction-fee",
  TRANSACTION_PAYMENT = "transaction-pmt",
}

export const enum AccountEventWebhookType {
  CARD_EXPIRING = "card_expiring",
  CARD_SHIPPED = "card_shipped",
}

export const enum SimulationType {
  FULL_CYCLE = "full-cycle",
  GENERATE_PAYLOAD = "generate-payload",
  SIMULATE_WITH_PAYLOAD = "simulate-with-payload",
}

export const enum RDFProcessingStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  SKIPPED = "SKIPPED",
}
