import React from "react";
import { FieldInputProps, useField } from "formik";

interface CustomInputProps extends Partial<FieldInputProps<string>> {
  label: string;
  name: string;
  placeholder: string;
  disabled?: boolean;
}

export const TextArea: React.FC<CustomInputProps> = ({
  label,
  name,
  placeholder,
  disabled,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });

  return (
    <>
      <label>{label}</label>
      <textarea
        {...field}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        className={`
            ${meta.touched && meta.error ? "input-error" : ""} 
            ${disabled ? "input-disabled" : ""}
            'textarea-custom-color'
            `.trim()}
      />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </>
  );
};
