import * as yup from "yup";
import { merchantCategoryCodes } from "../data/merchantCategoryCodes";
import { webhookTypeOptions, galileoCardStatuses } from "../data/formData";
import { SettlementWebhookType, WebhookType } from "../data/enums";
import { FormValues } from "../components/CardTransactionSimulationForm";

const requiredMessage = "Required";
const numbersAllowedOnlyMessage = "Only numbers are allowed";
const regex = /^[+-]?[0-9]*(?:\.[0-9]*)?$/;

export const cardTransactionSimulationFormSchema = yup.object<FormValues>({
  webhookServer: yup.string().required(requiredMessage),
  webhookType: yup
    .string()
    .oneOf(webhookTypeOptions.map(({ value }) => value))
    .required(requiredMessage),
  eventType: yup.string().required(requiredMessage),
  authId: yup.string().when("webhookType", {
    is: webhookType =>
      webhookType == null ||
      [WebhookType.SETTLEMENT.toString(), WebhookType.AUTHORIZATION_EVENT.toString()].includes(
        webhookType,
      ),
    then: yup.string().required(requiredMessage),
  }),
  originalAuthId: yup.string(),
  amount: yup.string().when("webhookType", {
    is: webhookType => ![WebhookType.ACCOUNT_EVENT.toString()].includes(webhookType),
    then: yup.string().matches(regex, numbersAllowedOnlyMessage).required(requiredMessage),
  }),
  remainingAmount: yup.string().when("eventType", {
    is: eventType => eventType == SettlementWebhookType.INCREMENTAL_CLEARING,
    then: yup.string().matches(regex, numbersAllowedOnlyMessage).required(requiredMessage),
  }),
  description: yup.string().when("webhookType", {
    is: webhookType => webhookType == WebhookType.TRANSACTION_EVENT,
    then: yup.string().required(requiredMessage),
  }),
  merchantName: yup.string().when("webhookType", {
    is: webhookType =>
      webhookType == null ||
      [
        WebhookType.AUTHORIZATION.toString(),
        WebhookType.SETTLEMENT.toString(),
        WebhookType.AUTHORIZATION_EVENT.toString(),
      ].includes(webhookType),
    then: yup.string().required(requiredMessage),
  }),
  merchantCategoryCode: yup.string().when("webhookType", {
    is: webhookType =>
      webhookType == null ||
      [
        WebhookType.AUTHORIZATION.toString(),
        WebhookType.SETTLEMENT.toString(),
        WebhookType.AUTHORIZATION_EVENT.toString(),
      ].includes(webhookType),
    then: yup
      .string()
      .oneOf(merchantCategoryCodes.map(({ mcc }) => mcc))
      .required(requiredMessage),
  }),
  international: yup.bool().required(requiredMessage),
  cardId: yup.string().matches(regex, numbersAllowedOnlyMessage),
  galileoCardId: yup.string().matches(regex, numbersAllowedOnlyMessage),
  paymentReferenceNumber: yup.string(),
  globalCardId: yup.string(),
  cardStatus: yup.string().when("webhookType", {
    is: webhookType =>
      webhookType == null ||
      [
        WebhookType.AUTHORIZATION.toString(),
        WebhookType.AUTHORIZATION_EVENT.toString(),
        WebhookType.TRANSACTION_EVENT.toString(),
      ].includes(webhookType),
    then: yup.string().oneOf(galileoCardStatuses).required(requiredMessage),
  }),
  additionalOptions: yup.bool().required(requiredMessage),
  responseCodeObject: yup.string(),
  responseCode: yup.string(),
  postOnlyToRDF: yup.boolean(),
});
