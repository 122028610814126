import React from "react";
import { useField, FieldInputProps } from "formik";

interface CustomSelectProps extends Partial<FieldInputProps<string>> {
  label: string;
  name: string;
  children: React.ReactNode;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<CustomSelectProps> = ({
  label,
  children,
  name,
  disabled,
  onChange,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });

  return (
    <>
      <label>{label}</label>
      <select
        {...field}
        onChange={e => {
          field.onChange(e);
          onChange?.(e);
        }}
        {...props}
        disabled={disabled}
        className={`${meta.touched && meta.error ? "input-error" : ""} ${
          disabled ? "input-disabled" : ""
        }`.trim()}
      >
        {children}
      </select>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </>
  );
};
