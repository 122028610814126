import React from "react";
import Modal from "react-modal";

interface AuthModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onRequestClose, onSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Authorization successful, you can now settle the transaction."
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <h3>Authorization successful, you can now settle the transaction.</h3>
      <br />
      <button onClick={() => onSubmit()}>Settle</button>
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
};
