import React from "react";
import ReactJson from "react-json-view";
import { EventResponseCode, GalileoResponseCode } from "../data/enums";

interface SimulationProps {
  simulationProps?: SimulationResponse;
  authIdProp?: string;
}

export type SimulationResponse = {
  authorization?: {
    auth_id?: string;
    response?: {
      response_code: string;
    };
  };
  event?: {
    response?: {
      success_code: string;
    };
  };
  reply?: {
    amount: string;
    card_id: string;
    mcc: string;
    merchant: string;
  };
  settlement?: {
    response?: {
      success_code: string;
    };
    bookkeeping_auth_id?: number;
  };
  json_payload?: string;
  raw_response?: string;
  rdf_post_status?: string;
};

const authResponseTranslates: { [key: string]: string } = {
  [GalileoResponseCode.SUCCESS]: "SUCCESS",
  [GalileoResponseCode.ISSUER_ERROR]: "ISSUER_ERROR",
  [GalileoResponseCode.MCC_BLOCKED]: "MCC_BLOCKED",
  [GalileoResponseCode.PICK_UP]: "PICK_UP",
  [GalileoResponseCode.DO_NOT_HONOR]: "DO_NOT_HONOR",
  [GalileoResponseCode.ERROR]: "ERROR",
  [GalileoResponseCode.PARTIAL_APPROVAL]: "PARTIAL_APPROVAL",
  [GalileoResponseCode.MAX_SYSTEM_AMOUNT_REACHED]: "MAX_SYSTEM_AMOUNT_REACHED",
  [GalileoResponseCode.INVALID_PAN]: "INVALID_PAN",
  [GalileoResponseCode.FORMAT_ERROR]: "FORMAT_ERROR",
  [GalileoResponseCode.LOST_FRAUD_PICK_UP]: "LOST_FRAUD_PICK_UP",
  [GalileoResponseCode.STOLEN_FRAUD_PICK_UP]: "STOLEN_FRAUD_PICK_UP",
  [GalileoResponseCode.INSUFFICIENT_FUNDS]: "INSUFFICIENT_FUNDS",
  [GalileoResponseCode.CARD_EXPIRED]: "CARD_EXPIRED",
  [GalileoResponseCode.INVALID_PIN]: "INVALID_PIN",
  [GalileoResponseCode.TRANSACTION_NOT_PERMITTED_TO_CARDHOLDER]:
    "TRANSACTION_NOT_PERMITTED_TO_CARDHOLDER",
  [GalileoResponseCode.SUSPECTED_FRAUD]: "SUSPECTED_FRAUD",
  [GalileoResponseCode.EXCEEDS_AMOUNT_LIMIT]: "EXCEEDS_AMOUNT_LIMIT",
  [GalileoResponseCode.CARD_FROZEN]: "CARD_FROZEN",
  [GalileoResponseCode.SECURITY_VIOLATION]: "SECURITY_VIOLATION",
  [GalileoResponseCode.EXCEEDS_COUNT_LIMIT]: "EXCEEDS_COUNT_LIMIT",
  [GalileoResponseCode.PIN_COUNT_EXCEEDED]: "PIN_COUNT_EXCEEDED",
  [GalileoResponseCode.CARD_RESTRICTED]: "CARD_RESTRICTED",
  [GalileoResponseCode.AVS_ONLY]: "AVS_ONLY",
  [GalileoResponseCode.PARTIAL_APPROVAL_POS_ONLY]: "PARTIAL_APPROVAL_POS_ONLY",
  [GalileoResponseCode.SYSTEM_ERROR]: "SYSTEM_ERROR",
  [GalileoResponseCode.RHO_INTERNAL_ERROR]: "RHO_INTERNAL_ERROR",
  [GalileoResponseCode.INVALID_CVV2]: "INVALID_CVV2",
};

const eventResponseTranslates: { [key: string]: string } = {
  [EventResponseCode.SUCCESS]: "SUCCESS",
  [EventResponseCode.PARAMETERS_DO_NOT_PASS_VALIDATION]: "PARAMETERS_DO_NOT_PASS_VALIDATION",
  [EventResponseCode.CARDHOLDER_ACCOUNT_NOT_IN_SYSTEM]: "CARDHOLDER_ACCOUNT_NOT_IN_SYSTEM",
  [EventResponseCode.GENERAL_SYSTEM_FAILURE]: "GENERAL_SYSTEM_FAILURE",
  [EventResponseCode.AUTHENTICATION_FAILED]: "AUTHENTICATION_FAILED",
  [EventResponseCode.NOT_READY_TO_ACCEPT_MESSAGES]: "NOT_READY_TO_ACCEPT_MESSAGES",
};

export const CardTransactionSimulationResponseTable = ({
  simulationProps,
  authIdProp,
}: SimulationProps) => {
  const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  if (
    !simulationProps ||
    (!simulationProps.authorization &&
      !simulationProps.settlement &&
      !simulationProps.event &&
      !simulationProps.raw_response &&
      !simulationProps.rdf_post_status)
  ) {
    return null;
  }
  const { authorization, settlement, event, raw_response } = simulationProps;

  const authId = authorization?.auth_id ?? authIdProp;
  const authResponse = translateAuthResponseCode(authorization?.response?.response_code);
  const settlementResponse = translateEventResponseCode(settlement?.response?.success_code);
  const eventResponse = translateEventResponseCode(event?.response?.success_code);
  const bookkeepingAuthId = settlement?.bookkeeping_auth_id;
  const rdfPostStatus = simulationProps.rdf_post_status;
  const rawResponse = raw_response;
  const showSimulationResponse =
    simulationProps.authorization ||
    simulationProps.settlement ||
    simulationProps.event ||
    simulationProps.rdf_post_status;
  const showRawResponse = rawResponse;

  return (
    <>
      <h3 className="table-title">Simulation Response</h3>
      {showSimulationResponse && (
        <div className="table-container">
          <table className="response-table">
            <thead>
              <tr>
                <th>Auth ID</th>
                <th>Auth Response</th>
                <th>Settlement Response</th>
                <th>Event Response</th>
                {rdfPostStatus && <th>RDF Post Status</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{authId}</td>
                <td>{authResponse}</td>
                <td>
                  {settlementResponse}
                  <br />
                  {bookkeepingAuthId && (
                    <>
                      Bookkeeping auth ID: <label>{bookkeepingAuthId}</label>
                    </>
                  )}
                </td>
                <td>{eventResponse}</td>
                {rdfPostStatus && <td>{rdfPostStatus}</td>}
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <br />
      {showRawResponse && (
        <div className="table-container">
          <ReactJson name={null} style={styles} src={JSON.parse(rawResponse ?? "{}")} />
        </div>
      )}
    </>
  );
};

const translateAuthResponseCode = (responseCode: string | null | undefined): string => {
  return !responseCode ? "" : authResponseTranslates[responseCode] || "Unknown Error";
};

const translateEventResponseCode = (responseCode: string | null | undefined): string => {
  return !responseCode ? "" : eventResponseTranslates[responseCode] || "Unknown Error";
};
